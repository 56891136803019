<template>
  <!-- DETAILS PANEL - For displaying a courses details -->
  <v-row>
    <v-col v-if="!courseInfo.code">
      <v-row v-for="n in 3" :key="n">
        <v-row v-if="onDesktop && !mobile">
          <v-col cols="2" class="pt-8"><v-skeleton-loader type="text"/></v-col>
          <v-col cols="10"><v-skeleton-loader type="list-item-two-line"/></v-col>
        </v-row>
        <v-col v-else class="px-0">
          <v-skeleton-loader type="text" max-width="150"/>
          <v-skeleton-loader type="list-item-two-line" style="margin: 0 -15px"/>
        </v-col>
      </v-row>
    </v-col>
    <!-- DESKTOP VERSION - Double column info display -->
    <span v-if="(autoSize && onDesktop && !mobile) || (!autoSize && $vuetify.breakpoint.xlOnly)" class="fillWidth">
      <v-row v-for="(detail, idx) in courseHeaders" :key="idx">
        <v-col v-if="courseInfo[detail.field] && (detail.field !== 'semesters' || showSemesters)" cols="2"
               :class="dense ? 'font-weight-medium pb-1' : 'font-weight-medium pb-2'">
          <p class="mb-0">{{ detail.label }}
            <v-tooltip v-if="detail.field === 'prereq' && showTree" bottom color="tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-2 itemHover" color="accent" small
                        @click="viewTree(courseInfo.code)">mdi-sitemap</v-icon>
              </template>
              <span class="font-weight-medium">View prereq tree</span>
            </v-tooltip>
          </p>
        </v-col>
        <v-col v-if="courseInfo[detail.field] && (detail.field !== 'semesters' || showSemesters)" cols="10" :class="dense ? 'pb-1' : 'pb-2'" style="max-width: 1500px;">
          <SemesterChips v-if="detail.field === 'semesters' && showSemesters" :course="courseInfo.code" :semesters="courseInfo[detail.field]"/>
          <v-runtime-template v-else :template="dynamicText(courseInfo[detail.field], false)"/>
        </v-col>
      </v-row>
    </span>
    <!-- MOBILE VERSION - Single column info display -->
    <span v-else class="fillWidth">
      <v-row v-for="(detail, idx) in courseHeaders" :key="idx">
        <v-col v-if="courseInfo[detail.field] && (detail.field !== 'semesters' || showSemesters)">
          <v-row class="font-weight-medium px-3 pt-2 pb-1">
            <p class="mb-0">{{ detail.label }}</p>
            <v-tooltip v-if="detail.field === 'prereq' && showTree" bottom color="tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2 itemHover" color="accent" small
                      @click="viewTree(courseInfo.code)">mdi-sitemap</v-icon>
            </template>
            <span class="font-weight-medium">View prereq tree</span>
          </v-tooltip>
          </v-row>
          <v-row class="text--accent-1 pb-3 px-3">
            <SemesterChips v-if="detail.field === 'semesters' && showSemesters" :course="courseInfo.code" :semesters="courseInfo[detail.field]"/>
            <v-runtime-template v-else :template="dynamicText(courseInfo[detail.field], detail.field === 'description')"/>
            <p v-if="!onDesktop && courseInfo[detail.field].length > 300 && !showMore"
               class="itemHover accent--text mb-0 text-decoration-underline"
               @click="showMore = true; courseInfo.description = courseInfo.description + ''">
              Read more
            </p>
          </v-row>
        </v-col>
      </v-row>
    </span>
    <v-row v-if="viewMore" justify="center" class="mt-7 mb-0">
      <a class="text-body-1 accent--text font-weight-medium itemHover" @click="viewCourse(courseInfo, true)">View more</a>
    </v-row>
    <!-- PREREQ MODAL - Quickly view prereq info -->
    <v-dialog v-model="prereqModal" @click:outside="modalMore = false" max-width="500px">
      <v-card v-if="coursePreview" :outlined="store.app.darkMode" style="border-radius: 5px" :loading="loadingPreview">
        <v-card-title class="pt-6 pb-2 pb-md-3 text-break font-weight-bold text-md-h5">
          {{ coursePreview.course.slice(0, 8) }}
          <QuickStats v-if="!onMobile" :stats="coursePreview" css="ml-4 d-flex align-center" spacing="3"/>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row class="px-3">
            <p class="font-weight-regular text-body-1 text-md-h6 mb-0">{{ coursePreview.course.slice(10) }}</p>
          </v-row>
          <QuickStats v-if="onMobile" :stats="coursePreview" css="d-flex pt-4" spacing="3"/>
          <p class="mb-0 font-weight-medium pt-5 pt-md-8">Description</p>
          <p class="mb-0 mt-2 text--secondary">{{ modalCourseDesc }}</p>
          <p v-if="coursePreview.description.length > 300 && !modalMore"
             class="itemHover accent--text mb-0 text-decoration-underline" @click="modalMore = true">
            Read more
          </p>
          <p class="mb-0 font-weight-medium py-3 mr-5">Current Offerings</p>
          <semester-chips :course="coursePreview.course.slice(0, 8)" :semesters="coursePreview.semesters"/>
        </v-card-text>
        <v-divider class="mt-5"/>
        <v-card-actions class="py-3">
          <v-spacer/>
          <v-btn color="accent" class="px-4 mr-2" outlined @click="prereqModal = false; modalMore = false">Close</v-btn>
          <v-btn color="accent" depressed class="px-4" @click="viewCourse(coursePreview.course.slice(0, 8), false)">View Course</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'
import SemesterChips from '@/components/SemesterChips'
import QuickStats from '@/components/QuickStats'
import { mapState } from 'pinia'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'InfoDisplay',
  components: { VRuntimeTemplate, SemesterChips, QuickStats },
  props: {
    courseInfo: { type: Object, required: true },
    viewMore: { type: Boolean, default: false },
    showTree: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    dynamicCodes: { type: Boolean, default: false },
    showSemesters: { type: Boolean, default: false },
    autoSize: { type: Boolean, default: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    prereqModal: false,
    coursePreview: null,
    loadingPreview: true,
    showMore: false,
    modalMore: false,
    courseHeaders: [
      { field: 'description', label: 'Description' },
      { field: 'section', label: 'Field of Study' },
      { field: 'breadth', label: 'Breadth REQ' },
      { field: 'distribution', label: 'Distribution REQ' },
      { field: 'prereq', label: 'Prerequisites' },
      { field: 'coreq', label: 'Co-requisites' },
      { field: 'preparation', label: 'Recommended Preparation' },
      { field: 'exclusion', label: 'Course Exclusions' },
      { field: 'limits', label: 'Enrolment Limits' },
      { field: 'notes', label: 'Notes' },
      { field: 'semesters', label: 'Semesters' }
    ]
  }),
  computed: {
    ...mapState(useAllStores, {
      onMobile: store => store.app.onMobile,
      onDesktop: store => store.app.onDesktop
    }),
    modalCourseDesc: function () {
      const desc = this.coursePreview.description || ''
      return desc.length > 300 && !this.modalMore ? desc.slice(0, 300) + '...' : desc
    }
  },
  methods: {
    viewTree (course) {
      this.$router.push({ path: '/tree', query: { c: course, v: '0' } })
    },
    viewCourse (course, hasData) {
      // Set the appropriate store value if we have partial data or just the code
      if (hasData) {
        this.store.data.setSelectedCourseDetails({ data: course })
      } else {
        this.store.data.setSelectedCourseDetails({ })
      }
      this.$router.push({ path: '/courses', query: { c: hasData ? course.code : course } })
      this.prereqModal = false
    },
    dynamicText (text, truncate) {
      if (Array.isArray(text)) text = text.join(', ')
      text = !this.store.app.onDesktop && truncate && text.length > 300 && !this.showMore ? text.slice(0, 300) + '...' : text
      const emailRGX = /(?<email>([a-z\d]+\.?-?[a-z\d]+@[a-z]*\.?utoronto\.ca))/g
      const urlRGX = /(?<url>(?:(?:https?|ftp):)?\/\/(www|q)+[\w/\-?=%.]+\.[\w/\-&?=%]+)/g
      let courseInfo = '<p class="mb-0">' + text + '</p>'
      // Only if we want course preview activated
      if (this.dynamicCodes) {
        const courseRGX = /(?<course>[A-Z]{3}([A-Z]|[0-4])\d{2}[A-Z]\d)/g
        courseInfo = courseInfo.replace(courseRGX, '<span class="itemHover font-weight-medium accent--text text-decoration-underline" @click="getCoursePreview(`$<course>`)">$<course></span>')
      }
      // Activate links and emails
      courseInfo = courseInfo.replace(emailRGX, '<a class="itemHover accent--text font-weight-regular text-decoration-underline" :href="`mailto:$<email>`">$<email></a>')
      courseInfo = courseInfo.replace(urlRGX, '<a class="itemHover accent--text font-weight-regular text-decoration-underline" :href="`$<url>`" target="_blank">$<url></a>')
      return (courseInfo)
    },
    async getCoursePreview (course) {
      this.coursePreview = null
      this.loadingPreview = true
      // Call backend for course details
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { course, description, semesters, bird, drop, rating, numReviews } }',
        variables: { course: course },
        operationName: 'courseInfo'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data.courseInfo.length === 0) {
              this.$toast.warning('Sorry! We could not find a course matching: ' + course)
            } else {
              this.prereqModal = true
              this.coursePreview = graphQlRes.data.courseInfo[0]
              // GTAG
              this.$gtag.event('preview_' + course, { value: 1 })
            }
            this.loadingPreview = false
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    }
  }
}
</script>

<style scoped>
  >>>a {
    font-size: inherit;
  }
  >>>.itemHover:hover {
    cursor: pointer;
    color: #0789F2 !important;
    text-decoration: underline #0789F2;
  }
  .fillWidth {
    width: 100%;
  }

</style>
